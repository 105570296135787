<template>
    <div>
        <el-container>
            <el-header>忆时光</el-header>
            <el-container>
                <el-aside width="200px">
                    <el-menu active-text-color="#f5b1aa" :default-active="$route.path" router>
                        <el-menu-item index="/">
                            <i class="el-icon-s-home"></i>
                            我的家
                        </el-menu-item>
                        <el-menu-item index="/flower">
                            <i class="el-icon-trophy"></i>
                            小红花中心
                        </el-menu-item>
                        <el-menu-item index="/exchange">
                            <i class="el-icon-s-goods"></i>
                            礼物中心
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    export default {
        name: 'Navigation'
    }
</script>

<style>
.el-header {
    background-color: #f5b1aa;
    color: #FFFFFF;
    text-align: left;
    line-height: 60px;
    font-size: 24px;
}

body {
    margin: 0;
    padding: 0;
}

</style>
