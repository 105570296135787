import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import App from './App.vue'
import Login from './components/Login.vue'
import Navigation from './components/Navigation.vue'
import Home from './components/Home.vue'
import Exchange from './components/Exchange.vue'
import Flower from './components/Flower.vue'
import axios from 'axios'

axios.defaults.baseURL = 'https://dmx.org.cn/api'
axios.defaults.headers.post['Content-Type'] = 'application/json'

const routes = [
    {
        path: '/', 
        component: Navigation,
        children: [{
            path: '/',
            component: Home
        }, {
            path: '/exchange',
            component: Exchange
        }, {
            path: '/flower',
            component: Flower
        }]
    },
    {
        path: '/login', 
        component: Login
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    let token = app.config.globalProperties.$token
    let user = app.config.globalProperties.$user

    if (!token) {
        app.config.globalProperties.$token = sessionStorage.getItem('token')
        token = app.config.globalProperties.$token
    }

    if (!user) {
        app.config.globalProperties.$user = sessionStorage.getItem('user')
    }
    
    if (to.path !== '/login' && !token) next('/login')
    else next()
})

function dateToString(date) {
    let year = date.getFullYear()
    let month = (date.getMonth() + 1).toString().padStart(2, 0)
    let day = date.getDate().toString().padStart(2, 0)
    let hours = date.getHours().toString().padStart(2, 0)
    let minutes = date.getMinutes().toString().padStart(2, 0)
    let seconds = date.getSeconds().toString().padStart(2, 0)

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}

const app = createApp(App)
app.use(router)
app.use(ElementPlus, { locale })
app.config.globalProperties.$axios = axios
app.config.globalProperties.$token = ''
app.config.globalProperties.$user = ''
app.config.globalProperties.$dateToString = dateToString
app.mount('#app')
