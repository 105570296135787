<template>
    <div>
        <el-avatar :size="120">{{ nickName }}</el-avatar>
        <el-divider></el-divider>
        <el-descriptions style="width:40%" title="宝贝信息" :column="1" border>
             <el-descriptions-item>
                 <template #label>
                     <i class="el-icon-user"></i>
                     用户名
                 </template>
                 {{ this.$user }}
             </el-descriptions-item>
             <el-descriptions-item>
                 <template #label>
                     <i class="el-icon-user-solid"></i>
                     昵称
                 </template>
                 {{ nickName }}
             </el-descriptions-item>
             <el-descriptions-item>
                 <template #label>
                     <i class="el-icon-trophy"></i>
                     小红花
                 </template>
                 {{ flower }}
             </el-descriptions-item>
        </el-descriptions>
        <br />
        <el-table :data="awardTable.slice((awardCurrentPage - 1) * pageSize, awardCurrentPage * pageSize)" border>
            <el-table-column prop="e_time" label="奖励时间">
            </el-table-column>
            <el-table-column prop="e_desc" label="奖励事件">
            </el-table-column>
            <el-table-column prop="e_flower" label="奖励小红花数量">
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="awardTable.length" @current-change="awardPageChanged"></el-pagination>
        <br />
        <el-table :data="punishTable.slice((punishCurrentPage - 1) * pageSize, punishCurrentPage * pageSize)" border>
            <el-table-column prop="e_time" label="惩罚时间">
            </el-table-column>
            <el-table-column prop="e_desc" label="惩罚事件">
            </el-table-column>
            <el-table-column prop="e_flower" label="扣除小红花数量">
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="punishTable.length" @current-change="punishPageChanged"></el-pagination>
        <br />
        <el-table :data="exchangeTable.slice((exchangeCurrentPage - 1) * pageSize, exchangeCurrentPage * pageSize)" border>
            <el-table-column prop="e_time" label="兑换时间">
            </el-table-column>
            <el-table-column prop="e_desc" label="兑换物品">
            </el-table-column>
            <el-table-column prop="e_flower" label="使用小红花数量">
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="exchangeTable.length" @current-change="exchangePageChanged"></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            nickName: 'user',
            flower: 0,
            awardTable: [],
            punishTable: [],
            exchangeTable: [],
            awardCurrentPage: 1,
            punishCurrentPage: 1,
            exchangeCurrentPage: 1,
            pageSize: 10
        }
    },
    mounted() {
        var _this = this

        //获取用户信息
        this.$axios.post('/getUserInfo', {
            userName: this.$user,
            token: this.$token
        })
        .then(function (response) {
            var res = response['data']

            if (res['code'] == 1) {
                _this.nickName = res['nickName']
                _this.flower = res['flower']
            }
        })

        //获取奖励事件
        this.$axios.post('/getEvent', {
            userName: this.$user,
            token: this.$token,
            eventType: 1
        })
        .then(function (response) {
            var res = response['data']

            if (res['code'] == 1) {
                _this.awardTable = res['table']
            }
        })

        //获取惩罚事件
        this.$axios.post('/getEvent', {
            userName: this.$user,
            token: this.$token,
            eventType: 0
        })
        .then(function (response) {
            var res = response['data']

            if (res['code'] == 1) {
                _this.punishTable = res['table']
            }
        })
    },
    methods: {
        awardPageChanged(page) {
            this.awardCurrentPage = page
        },
        punishPageChanged(page) {
            this.punishCurrentPage = page
        },
        exchangePageChanged(page) {
            this.exchangeCurrentPage = page
        }
    }
}
</script>

<style>
.el-avatar {
    background-color: #f5b1aa;
    font-size: 24px;
}
</style>
