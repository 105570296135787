<template>
    <div>
        <el-form :model="fwForm" label-width="100px">
            <el-form-item label="事件类型">
                <el-select v-model="fwForm.fwType" placeholder="请选择事件类型">
                    <el-option label="奖励" value="1"></el-option>
                    <el-option label="惩罚" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="事件时间">
                <el-date-picker v-model="fwForm.fwTime" type="datetime" placeholder="请选择日期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="事件内容">
                <el-input type="textarea" v-model="fwForm.fwDesc" placeholder="请输入事件内容"></el-input>
            </el-form-item>
            <el-form-item label="小红花数量">
                <el-input-number v-model="fwForm.fwCount" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item>
                <el-popconfirm title="确定提交吗？" @confirm="pushEvent">
                    <template #reference>
                        <el-button type="primary">立即提交</el-button>
                    </template>
                </el-popconfirm>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
import { ElNotification } from 'element-plus'

export default {
    name: 'Flower',
    data() {
        return {
            fwForm: {
                fwType: '',
                fwTime: '',
                fwDesc: '',
                fwCount: 1
            }
        }
    },
    methods: {
        pushEvent() {
            if (!this.fwForm.fwTime || !this.fwForm.fwType || !this.fwForm.fwDesc) {
                ElNotification({
                    title: '小红花',
                    message: '请填写完整信息',
                    type: 'warning'
                })

                return
            }

            if (this.fwForm.fwDesc.length >= 100) {
                ElNotification({
                    title: '小红花',
                    message: '描述长度应小于100个字符',
                    type: 'warning'
                })

                return
            }

            let curTime = this.$dateToString(this.fwForm.fwTime)

            this.$axios.post('/pushEvent', {
                userName: this.$user,
                token: this.$token,
                fwType: parseInt(this.fwForm.fwType),
                fwTime: curTime,
                fwDesc: this.fwForm.fwDesc,
                fwCount: this.fwForm.fwCount
            })
            .then(function (response) {
                var res = response['data']

                if (res['code'] == 1) {
                    ElNotification({
                        title: '小红花',
                        message: '提交成功',
                        type: 'success'
                    })
                } else {
                    ElNotification({
                        title: '小红花',
                        message: '提交失败',
                        type: 'error'
                    })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }
    }
}
</script>

<style>
.el-form {
    width: 50%;
    text-align: left;
}
</style>
