<template>
  <router-link to="/"></router-link>
  <router-link to="/login"></router-link>
  <router-view></router-view>

  <div v-if="$route.path == '/login'" class="beian-footer">
    <img class="beian-icon" src="../src/assets/beian.png" />
    <a class="beian-style" href="https://beian.mps.gov.cn/#/query/webSearch?code=33038102332356" rel="noreferrer" target="_blank">浙公网安备33038102332356</a >
    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023001355号</a>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.beian-icon {
  width: 20px;
  height: 20px;
}

.beian-style {
  margin-right: 20px;
}

.beian-footer {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 35%;
  bottom: 20px;
}
</style>
