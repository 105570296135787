<template>
  <div class="login">
    <div v-for="num in photoCount" :key="num" >
      <transition name="fade" mode="out-in">
        <!--
        <li v-if="photoShowIndex == Math.ceil(num/photoShowCount)"> {{ num }} </li>
        -->
        <img :src="getPhotoURL(num)" :style="getRandomStyle(num)" v-if="photoShowIndex == Math.ceil(num/photoShowCount)" />
      </transition>
    </div>
    <el-card class="login-form">
      <el-form>
        <h3>登陆</h3>
        <el-form-item>
          <el-input v-model="loginForm.userName" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="password" v-model="loginForm.password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login">登录</el-button>
        </el-form-item> 
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'

export default {
  name: 'Login',
  data: function() {
    return {
      photoCount: 33,
      photoShowCount: 3,
      photoShowIndex: 1,
      loginForm: {
        userName: '',
        password: ''
      }
    }
  },
  methods: {
    changePhotoIndex() {
      this.photoShowIndex++
      if (this.photoShowIndex > Math.ceil(this.photoCount / this.photoShowCount)) {
        this.photoShowIndex = 1
      }
    },
    getPhotoURL(num) {
      return require("../assets/memory_" + num + '.jpg')
    },
    getRandomStyle(num) {
      var index = num % this.photoShowCount
      var step = 55 / this.photoShowCount
      return {
        transform: 'rotate(' + this.randomNum(-50, 50) + 'deg)',
        width: '25%',
        position: 'absolute',
        left: this.randomNum(index * step, (index + 1) * step) + '%',
        top: this.randomNum(0, 20) + '%',
      }
    },
    randomNum(minNum, maxNum) {
      return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10)
    },
    login() {
      var _this = this
      this.$axios.post('/login', {
        userName: this.loginForm.userName,
        password: this.loginForm.password
      })
      .then(function (response) {
        var res = response['data']

        if (res['code'] == 1) {
          //登陆成功
          ElMessage.success('登陆成功')

          sessionStorage.setItem('token', res['token'])
          sessionStorage.setItem('user', _this.loginForm.userName)

          _this.$router.push('/')
        } else {
          //登陆失败
          ElMessage.error('用户名或密码错误')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
    }
  },
  mounted: function() {
    setInterval(this.changePhotoIndex, 5000)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to,
  .fade-leave-from {
    opacity: 0.8;
  }

  img {
    opacity: 0.8;
    border-radius: 25px;
    z-index: -1;
  }

  .login-form {
    margin: 100px auto;
    width: 400px;
    position: absolute; left: 75%; top: 20%; 
    border-radius: 15px;
  }
</style>
